
.navs{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-bottom: 40px;
}

.navs-tabs .navs-link {
    background: #FFFFFF;
    font-family: 'Roboto';
    color: #717171;
    font-weight: 600;
    font-size: 12px;
    border: none;
    padding: 15px 30px 15px;
    margin-right: 10px;
}
.navs-link.active{
    color: var(--color-primary);
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 12px;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 15px 30px 15px;
}
.plot_info{
    max-height: calc(100vh - 220px);
    overflow-y: auto;;
}
.plot-details{
    padding: 0px 0px;
    
}
.plot_headers{
    padding: 0px 20px;
    h1{
        font-family: 'Unna';
        font-weight: 700;
        font-size: 22px;
        color: #222222;
    }
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #717171;
    }
}
.property_val{
    margin-bottom: 30px;
}
.agent_info{
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    padding: 20px 20px;
    align-items: center;
}
.agent_name{
    h1{
        font-family: 'Unna';
        font-weight: 700;
        font-size: 22px;
        color: #222222;
        margin-bottom: 5px;
    }
    p{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 14px;
        color: #222222;    
    }
}
.agent_email_div{
    h5{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
        color: #717171;
    }
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #0047FF;
        overflow-wrap: break-word;
    } 
}
.agent_bio{
    h5{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
        color: #717171;

    }
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #222222;
    } 
}
.horizontal_lie{
    border: 1px solid#DDDDDD;
    margin-top: 20px;
    width: 100%;
}
.view_info{
    padding: 30px 0px;
    align-items: center;
    h1{
        font-family: 'Unna';
        font-weight: 700;
        font-size: 22px;
        color: #222222;
        margin-bottom: 5px;
    }
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #222222;    
    }
}

.verrtical_line{
    border: 1px solid #DDDDDD;
    width: 0px;
    height: 30px;
    margin: 0px 45px;
}
.customer_inter{
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background: #00B63E;
    display: flex;
    justify-content: center;
    align-items: center;
}
.customer_info{
    margin: 0px 20px;
}
.customer_approach{
    padding: 0px 100px;
}
.arrow_button{
    align-items: center;
    display: flex;
    justify-content: center;
}
.tick_mark{
    margin-left: -20px;
}
