.person_info{
    margin-bottom: 20px;
}
.add_property{
    h5{
        font-family: 'Unna';
        font-weight: 700;
        font-size: 32px;
        color: #222222;
    }
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #222222;
    }   
}
.personal_detail, .property_details{
    padding: 10px 20px;
    background: #FFFFFF;
    border: 1px solid #FFF2DF;
    border-radius: 20px;
    h5{
        font-family: 'Unna';
        font-weight: 700;
        font-size: 26px;
        color: #222222;
    }
}
.property_details{
    padding-bottom: 20px;
    // max-height: calc(100vh - 220px);
    // overflow-y: auto;;
}
.labels{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
    color: #002F10;
}
// .form-group{
//     margin-bottom:0px;
// }
.input_tabs{
    padding-left: 0px;
    padding-bottom: 20px;
}
.property_type{
    margin: 20px 0px;
    p{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 16px;
        color: #002F10;
        margin-bottom: 10px;
    }
}
.locality_title{
    font-family: 'Unna';
    font-weight: 700;
    font-size: 22px;
    color: #002F10;
}
.map_modal{
    padding: 30px 0px;
}
.property_links{
    border-bottom: 1px solid #DDDDDD;
    width: 100%;
    padding-bottom: 0px;
}
.properties_card{
    margin-bottom: 20px;
}
.property_dtl_card{
    border: none;
}
.property_body{
    border: 1px solid #DDDDDD;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 20px 20px;
}
.property_view{
    overflow-y: auto;
    height: calc(100vh - 220px);
}

.property_body{
    h5{
        font-family: 'Unna';
        font-weight: 700;
        font-size: 22px;
        color: #222222;
        margin-bottom: 0px;
    }
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #717171;
    }
    .cost{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 14px;
        color: #222222;
        padding-bottom: 10px;
    }
    button{
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 40px;
    }
}
.project_location{
    padding-bottom: 10px;
}
.edit_icon_info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    p{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 15px;
        color: #222222;
    }
}
.curve_icon{
    width: 55px;
    height: 54px;
    position: absolute;
    right: 10px;
    bottom: -25px;
}
.ubuntu_logo{
    position: absolute;
    left: 10px;
    bottom: 10px;
    background-color: #000000;
    border-radius: 10px;
    padding: 10px 10px;
}
.property_views{
    overflow-y: auto;
    height: calc(100vh - 220px);
}
.d-flex button {
    margin-right: 30px;
    border: 1px solid #D49941;
    border-radius: 10px;
    padding: 10px 30px;
    margin-bottom: 10px;
} 
.d-flex button:last-child {
    margin-right: 0;
}
.selected_value{
    background: #002F10;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
}
.unselected_btn{
    background: #FFF2DF;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 12px;
    color: #D49941;
}
.property_choose{
    margin-bottom: 20px;
}
.house_facing{
    p{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 16px;
        color: #002F10;
        margin-bottom: 10px;
    }
}
.locality_text{
    padding-bottom: 10px;
}
.locality_desc{
    margin-bottom: 20px;
    p{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 16px;
        color: #002F10;
        margin-bottom: 10px;
    }
}
.rooms_balconies{
    margin-bottom: 20px;
}
.floor_info, .total_floor_info,.saleable_area,.property_features,.property_dtl,.image_section,.trans_info{
    margin-bottom: 20px;
}
.publish_footer{
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    position: sticky;
}
.ask_me_text{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    color: #222222;
    padding: 0pc 5px;
}