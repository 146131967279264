.signup-popup-modal, .modal-dialog{
    max-width: 900px;
}
.singup-body {
    padding: 50px 30px;
    .col-lg-6{
      padding-left: 0px;
    }
}
.gold-brick-log{
    position: absolute;
    top: 0;
    left: 10%;
    img{
        width: 90px;
        height: 90px;
    }
}
.signup-modal-scroll {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
}
.bloc-tabs {
    display: flex;
}
.singup-tabs {
    background-color: #fff;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 0px;
}
.asteric{
    color: #EE2F2F;
}
.nav-tabs {
    border-bottom: 1px solid #DDDDDD;
    position: relative;
}
.signup-btn{
    background: #002F10;
    border-radius: 20px;
    width: 100%;
    border: none;
}
.signup-form{
    padding: 20px 0px;
}
.login-form{
    margin-bottom: 120px;
}
.signin-form{
    padding: 30px 0px;
}
.labels{
    font-weight: 400;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}
.submit-text{
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
}
.goldbrick-text{
    font-weight: 700;
    font-size: 22px;
    color: #002F10;
}
.signup-otp{
    padding: 50px 0px;
    .otp-form{
        padding: 30px 0px;
    }
    .mobileNum{
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        font-family: 'Roboto', sans-serif;
    }
}
.agree-terms{
    padding: 30px 0px;
    font-family: 'Roboto', sans-serif;
    .verify-div{
        margin-bottom: 50px;
    }
    .mobileNum{
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        color: #222222;
    }
    .plicy-terms{
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        color: #222222;
    }
}
