.menu_navigation{
    border: none;
    background: none;
}
.post_prtoprty_img{
    width: 160px;
    height: 50px;
}
.flex_class {
    display: flex;
    align-items: center;
    margin-right: 30px;

  }
.menu_img{
    width: 100px;
    height: 50px;
}
.button img{
    margin: 0px;
}
.containers{
    max-width: 100%;
    margin: 0 auto;
    padding: 0px 40px;
}
.layout {
    display: grid;
    grid-template-columns: 13.2rem 1fr;
    grid-template-rows: 0px 71px 0px;
    min-height: 100vh;
  }
  
  .header {
    grid-area: 2 / 2 / 2 / 2;
    border-bottom: 1px solid #e4e4e4;
    background-color: #fff;
  }
  
  .aside {
    grid-area: 2 / 1 / 4 / 2;
  }

  #main {
    grid-area: 3 / 2 / 3 / 3;
    height: calc(100vh - 71px);
    overflow-x: hidden;
    position: relative;
  }
  #main.tooggled{
    margin-left: -130px;
  }
#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow: hidden;
  }
  
  #wrapper.toggled {
    padding-left: 210px;
    overflow: scroll;
  }
  
  #sidebar-wrapper {
    z-index: 1000;
    position: absolute;
    left: 210px;
    width: 0;
    height: 100%;
    margin-left: -210px;
    overflow-y: auto;
    background: #FFFFFF;;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow-y: auto;
    height: calc(100vh - 71px);
  }
  
  #wrapper.toggled #sidebar-wrapper {
    width: 210px;
  }
  
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0px;
  }
  .sidebar-nav {
    position: absolute;
    top: 0px;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 2px;
    width: 210px;
    overflow-y: auto;
    height: calc(100vh - 140px);
  }
  .sidebar-nav li a {
    display: block;
    padding: 20px 30px 20px 15px;
    text-decoration: none;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16px;
    color: #002F10;;
    cursor: pointer;
    span {
      margin-left: 10px;
    }
    .icons {
      background-color: #f4f4f4;
      border-radius: 100%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
    }
  }
  .sidebar-nav li .side_nav_item:hover,
.sidebar-nav li .side_nav_item.active_item {
  text-decoration: none;
  background: linear-gradient(270deg, #D49941 5.14%, rgba(255, 153, 0, 0) 85.41%);
    border-radius:0px 40px 40px 0px;
  .icons {
    color: #002F10;;
  }
}

.sidebar-nav li .side_nav_item:active,
.sidebar-nav li .side_nav_item:focus {
  text-decoration: none;
}
.sidebar-nav li{
  padding-left: 10px;
}
.no-margin {
  margin: 0;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 210px;
  }
  .fixed-brand {
    border-bottom: 1px solid #e4e4e4;
  }
  .fixed-brand,
  #sidebar-wrapper {
    width: 210px;
    border-right: 1px solid #e4e4e4;
    top: 110px;
    z-index:auto;
  }
  #wrapper.toggled {
    padding-left: 0;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 210px;
  }
  #wrapper.toggled-2 #sidebar-wrapper {
    width: 60px;
    .sidebar-nav li a {
      // margin-left: 0px;
    }
  }
  #wrapper.toggled-2 #sidebar-wrapper:hover {
    width: 210px;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
    padding-left: 210px;
  }
  #wrapper.toggled-2 #page-content-wrapper {
    position: relative;
    margin-right: 0;
    margin-left: -210px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: auto;
  }
}
.header_names{
  font-family: 'Unna';
  font-weight: 700;
  font-size: 32px;
  color: #222222;

}
.product_logo{
  width: 230px;
}