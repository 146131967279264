body {
  margin: 0px;
  font-family: "Unna", serif;
  font-weight: var(--font-weight-regular);
}

.row,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}
ul {
  list-style: none;
  padding-left: 0px;
}
:root {
  --color-primary: #002f10;
  --color-secondary: #d49941;
  --color-card-light: #fff2df;
  --color-card-light-green: #defee9;
  --color-white: #fff;
  --color-black: #222222;
  --color-gray: #717171;
  --color-border: #dddddd;
  --font-weight-regular: normal;
  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-size-main-head: 92px;
  --font-size-ad-banner-head: 70px;
  --font-size-section-head: 52px;
  --font-size-body-text: 14px;
  --font-size-card-head-text: 22px;
  --font-size-button-text: 15px;
  --font-size-tab-text: 12px;
}
.black_color{
  color: var(--color-black)!important;
}
.nav-tabs {
  // border-bottom: none;
}
h1 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
}
button img {
  margin-right: 10px;
}
.primary_btn,
.secondry_btn,
.third_btn,
.outline_btn {
  border-radius: 20px;
  font-size: var(--font-size-button-text);
  padding: 12px 20px;
  border: none;
  font-family: "Roboto", sans-serif;
}
.nav_btn {
  border-radius: 40px;
}
.primary_btn {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}
.secondry_btn {
  background-color: var(--color-white);
  color: var(--color-black);
  border: 1px solid #dddddd;
}
.third_btn {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}
.outline_btn {
  background-color: transparent;
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
}
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 150px;
}
.allpage_banner {
  margin-top: 120px;
}
.body_para {
  font-family: "Roboto", sans-serif;
  font-size: var(--font-size-body-text);
  color: var(--color-black);
  line-height: 19px;
}
.grey_para {
  font-family: "Roboto", sans-serif;
  font-size: var(--font-size-body-text);
  color: var(--color-gray);
  line-height: 19px;
}
.bold_para {
  font-weight: var(--font-weight-bold);
}
.card_head {
  font-size: var(--font-size-card-head-text);
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
}
.white_color {
  color: var(--color-white);
}
.section_padding {
  padding: 80px 0px;
}
.section_head {
  color: var(--color-primary);
  font-size: var(--font-size-section-head);
  font-weight: var(--font-weight-bold);
}
.row_top {
  margin-top: 40px;
}
.Testimonial {
  background-color: var(--color-card-light);
}
// scrollbar css
#scroll_style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #002f10;
}
#scroll_style::-webkit-scrollbar {
  width: 3px;
  background-color: #002f10;
}
#scroll_style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--ono-primary);
}
#scroll_style1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
#scroll_style1::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}
#scroll_style1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #002f10;
}
.nav-tabs .nav-link {
  border-radius: 0px;
  color: #002f10;
  font-weight: 700;
  font-size: 22px;
  border: none;
  padding: 0px 10px 5px;
  margin-right: 10px;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  background-color: transparent;
  color: #002f10;
  border: none;
  border-bottom: 3px solid #002f10;
}
.nav-link:focus,
.nav-link:hover {
  border: none;
}
.main_div_padding {
  padding: 60px 20px 6px;
}
.agent_card {
  .agent_pic {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 20px;
  }
  .card {
    background-color: var(--color-primary);
    border-radius: 20px;
    padding: 50px;
  }
  .agent_card_body {
    padding-left: 30px;
  }
  .stars {
    margin-bottom: 20px;
  }
  .exp {
    margin-left: 20px;
    border-radius: 20px;
    border: 1px solid var(--color-secondary);
    padding: 20px 30px 20px 20px;
    p {
      margin-bottom: 10px;
    }
  }
  .details_div {
    img {
      margin-right: 10px;
    }
    p:last-child {
      font-weight: 500;
    }
  }
  .row {
    padding: 20px 0px;
  }
  .body_para {
    color: var(--color-white);
  }
  .btns_div {
    margin-top: 30px;
    button {
      margin-right: 20px;
    }
  }
}
.dot_border {
  border: 1px dotted var(--color-secondary);
  height: 1px;
  width: 100%;
}
.call_to_action {
  .card {
    background-color: var(--color-secondary);
    border-radius: 20px;
    padding: 40px;
  }
  p {
    margin: 20px 0px 30px;
  }
  button {
    display: flex;
    width: auto;
    margin-right: auto;
  }
  img {
    margin-top: 40px;
  }
}
.request_demoform {
    margin-top: 40px;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid var(--color-border);
  .card_head {
    margin-bottom: 20px;
  }
  .primary_btn{
      margin-top: 20px;
      width: 100%;
  }
}
.form_div {
  .form-control {
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    margin-bottom: 15px;
  }
}
.sticky_left{
    position: sticky;
    top: 100px;
}
.images_modal{
    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
      
      .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
        border: none;
        padding: 60px 0px;
      }
      .main_img{
          width: auto!important;
          object-fit: scale-down;
          border-radius: 20px;
      }
}
.popup_images{
    .owl-dots{
        display: none;
    }
    .owl-nav{
        display: block;
       position: absolute;
       top: 50%;
       width: 100%;
    }
    .owl-nav button.owl-prev, .owl-nav button.owl-next{
        position: absolute;
        top: 50%;
    }
    .owl-nav button.owl-prev{
        left: -43px;
        background: none!important;
        background-image: url("../img/icon/prev.svg")!important;
        background-repeat: no-repeat;
        width: 30px;
        height: 28px;
        span{
            display: none;
        }
    }
    .owl-nav button.owl-next{
        right: -20px;
        background: none!important;
        background-image: url("../img/icon/next_icon.svg")!important;
        background-repeat: no-repeat!important;
        width: 30px;
        height: 28px;
    }
}