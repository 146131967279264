.benefits_sec {
  .col-lg-3:nth-child(even) .card {
    background: var(--color-card-light-green);
  }
  .col-lg-3:nth-child(odd) .card {
    background: var(--color-card-light);
  }
  .col-lg-3 .ad_card {
    background: var(--color-secondary) !important;
  }
  .ad_card {
    p {
      margin-bottom: 12px;
    }
    button {
      margin: 0 auto;
    }
  }
  .row {
    margin-top: 10px;
  }
  .card {
    border: none;
    padding: 50px 20px;
    border-radius: 20px;
    margin-top: 30px;
    img {
      width: 40px;
      margin-bottom: 25px;
    }
    h5 {
      margin-bottom: 10px;
    }
    p {
      line-height: 22px;
    }
  }
}
.works_sec {
  padding: 0px 120px;
  .card {
    background-color: var(--color-card-light);
  }
}
.test_sec{
  padding: 0px 100px;
  .card{
    padding: 50px 30px;
    background-color: var(--color-primary);
    border-radius: 20px;
    img{
      width: 60px;
      height: 60px;
      border-radius: 20px;
    }
   .titles{
     margin: 20px 0px 25px;
   }
  }
}
.card_para{
  font-size: var(--font-size-tab-text);
  color: var(--color-white);
}