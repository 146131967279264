.footer {
  margin: 100px 0px 40px;
  font-family: "Roboto", sans-serif;
}
.footer_bg {
  background-color: var(--color-border);
  padding: 80px 100px;
  border-radius: 20px;
  position: relative;
  .logo {
    position: absolute;
    top: -150px;
  }
  p {
    margin: 50px 0px 40px;
  }
  h5 {
    font-family: "Unna", serif;
    margin-bottom: 10px;
  }
  .border_head {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(
      0.25turn,
      #d49941,
      #d49941,
      rgba(56, 18, 145, -22)
    );
    border-image-slice: 1;
    width: 40%;
    margin-bottom: 15px;
  }
  .address_head {
    width: 60%;
  }
}
.footer_list {
  margin-top: 25px;
  li {
    font-size: var(--font-size-body-text);
    color: var(--color-black);
    margin-bottom: 10px;
  }
}
.address {
  margin-top: 30px;
}
.callto_action{
    padding: 35px 25px;
    background-color: var(--color-primary);
    border-radius: 20px;
    p{
        margin: 10px 0px 30px;
    }
}
.footer_btn{
    width: 60%;
}