.welcome_card{
    background: #DEFEE9;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    margin-bottom: 20px
}
.property_card{
    background: #FFF2DF;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
}
.wel_gold{
    padding: 50px 50px;
}
.header-tag{
    font-family: 'Unna';
    font-weight: 700;
    font-size: 52px;
    color: #002F10;
    margin-bottom: 10px
}
.wel_text{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    color: #222222;
}
.visitors_div{
    background: #DEFEE9;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    height: 100%;
    padding: 50px 30px;
}
.dashboard-data{
    margin-bottom: 20px;
}
.properties_div{
    background: #FFF2DF;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    height: 100%;
    padding: 50px 30px;
}
.header-tag{
    color: var(--color-primary);
    font-family: 'Unna';
    font-weight: 700;
    font-size: 52px;
}
.text_val{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
}