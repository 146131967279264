.search_tabs {
 border-bottom: none;
 padding: 5px 0px 10px;
  .nav-link {
    font-family: "Roboto", sans-serif;
    background-color: transparent;
    color: var(--color-gray);
    font-size: var(--font-size-tab-text);
    font-weight: var(--font-weight-medium);
    padding: 5px 20px;
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    color: var(--color-black);
    background-color: transparent;
    border: none;
    background: linear-gradient(
      270deg,
      #d49941 -15.14%,
      rgba(255, 153, 0, 0) 85.41%
    );
    border-radius: 40px;
  }
}
.search_page{
  border-bottom: 1px solid var(--color-border);
}
.search_main_div{
  .main_img{
    border-radius: 20px 20px 0px 0px;
    height: 350px;
    object-fit: cover;
  }
}