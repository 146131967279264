.unselected_property{
    border-radius: 20px;
    background: #FFF2DF;
    padding: 20px 20px;
    border: 1px solid #D49941;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 12px;
    color: #D49941;
}
.selected_property{
    border-radius: 20px;
    padding: 20px 20px;
    background: #002F10;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border: 1px solid #D49941;
}
.properties{
    text-align: center;
}
.image-name{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    color: #222222;
    padding-top: 10px;
}
.housefacing button {
    margin-right: 20px;
    border: 1px solid #D49941;
    border-radius: 10px;
    padding: 10px 10px;
    margin-bottom: 10px;
} 
.d-flex button:last-child {
    margin-right: 0;
}
.roomsAndBalconies button{
    margin-right: 10px;
    border: 1px solid #D49941;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 10px;
}
.common_color{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
    color: #002F10;
    padding-bottom: 10px;
}
.floor_base button{
    margin-bottom: 10px;
}
.property_para{
    p{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 16px;
        color: #002F10;
    }
    margin-bottom: 20px;
}
.input_text{
    padding-bottom: 10px;
    .input_type_text{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 12px;
        color: #002F10;
    }
}
.form-check-input[type="checkbox"] {
    transform: scale(1.5); /* increase the size */
    background-color:#FFF2DF;; /* change the background color */
  }
.checkbox_input{
    margin-bottom: 20px;
}
.featuer_name{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    color: #717171;
    padding-top: 10px;
}
.featuers{
    text-align: center;
    margin-bottom: 20px;
}
.features_func{
    margin-bottom: 20px;
}
.privacy_policy{
    label{
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        color: #002F10;
    }
}
.exlusive_agree{
    margin-bottom: 20px;
    .first_check{
        padding-bottom: 20px;
    }
    label{
        padding-left: 10px;
    }
}
.publish_draft{
    padding: 20px 20px;
}
.draft_btn{
    background: #D49941;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    padding: 10px 20px;
    color: #002F10;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 15px;
    border: none;
}
.publish_btn{
    background: #002F10;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 15px;
    color: #D49941;
    padding: 10px 20px;
    border: none;
    margin-left: 20px;
}
.image_view{
    border-radius: 20px;
    padding: 20px 0px;
}
.preview-image{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.adder-image {
    width: 100%;
    height: 100%;
    background: #D9D9D9;
    border-radius: 20px;
    padding: 20px 0px;
    margin-top: 20px;
    margin-left: 20px;
}
.transaction_type button{
    margin-right: 40px;
    border: 1px solid #D49941;
    border-radius: 10px;
    padding:10px 20px;
    margin-bottom: 10px;
}