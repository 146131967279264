.profile-popup-modal{
    max-width: 900px;
    margin-top: 100px;
}
.profile_modal_body{
    padding: 30px 0px;
    .no_pad_btn{
        padding: 0px 0px;
    }
    .close_icon{
        margin-right: 0px;
    }
}
.modal_common_header{
    padding-left: 60px;
    h5{
        font-family: 'Unna';
        font-weight: 700;
        font-size: 22px;
        color: #002F10;
    }
}
.profile-modal-scroll{
    padding: 30px 60px;
    overflow-y: auto;
    height: calc(100vh - 240px);
    .form-group{
        margin-bottom: 20px;
    }
}
.about_inf{
    margin-bottom: 20px;
}
.danger_text{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    color: #EE2F2F;
}
.sumbit_btn{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 15px;
    color: #D49941;
    padding: 10px 30px;
    background: #002F10;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 40px;
    border: none;
}
.upload_pic{
    margin-top: 10px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}
.upload_photo{
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #0047FF;
    }
    img{
        background: #FFF2DF;
        border-radius: 100%;
        border:1px solid #FFF2DF
    }
}