.delete-property-popup-modal{
    max-width: 600px;
    margin-top: 200px;
}
.delete_property_body{
    padding: 50px 30px;
}
.header_tag{
    font-family: 'Unna';
    font-weight: 700;
    font-size: 26px;
    color: #222222;
    padding: 10px 0px;
}
.text_value{
    text-align: center;
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        color: #222222;
    }
}
.buttons{
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    button{
        padding: 10px 20px;
        border: none;
        background: linear-gradient(270deg, #D49941 5.14%, rgba(255, 153, 0, 0) 85.41%);
        border-radius: 40px;
    }
    .yes_btn{
        background: #002F10;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
    }
    p{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 15px;
    }
}
.paragraph_text{
    margin-bottom: 40px;
}

