
  a:hover {
    text-decoration: none;
  }
  .header {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  .navbar {
    padding: 0px;
    background-color: var(--color-white);
    padding: 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-border);
  }
  .navbar-brand {
    padding: 0px;
    margin: 0px;
    padding-right: 40px;
  }

  .navbar-expand-lg .navbar-nav .nav-item {
    margin-left: 20px;
  }
  .navbar-expand-lg .navbar-nav {
    align-items: center;
  }
  .logo_img:hover{
    cursor: pointer;
  }