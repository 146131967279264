.one_property {
 
  .card_head {
    margin-bottom: 30px;
  }
  position: relative;
}
.one_property_head {
  font-size: 32px;
  color: var(--color-black);
  font-weight: var(--font-weight-bold);
}
.one_property_head.white_color{
    color: var(--color-white);
}
.one_property_head_div {
  margin-left: 30px;
}
.images {
  margin-top: 20px;
  img {
    width: 100%;
    border-radius: 20px !important;
    object-fit: scale-down;
  }
}
.project_high_div {
  padding: 40px 0px;
  border-bottom: 1px solid var(--color-border);
 
  .prop_card_head {
    margin-bottom: 20px;
  }
  .body_para {
    margin-bottom: 10px;
  }
}
.project_about_div {
  p {
    margin-bottom: 20px !important;
  }
  .body_para:last-child {
    margin-bottom: 0px !important;
  }
}
.one_prop_page_tab {
  .nav-link.active {
    color: var(--color-black);
    background-color: var(--color-white)!important;
    border-radius: 20px;
    border: 1px solid var(--color-border)!important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08),
      0px 4px 12px rgba(0, 0, 0, 0.05);
  }
}
.plan_div{
    border-radius: 20px;
    border: 1px solid var(--color-border);
    padding: 5px;
    height: 180px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.ametites{
    p{
        margin-top: 10px;
        width: 80px;
    }
    margin-top: 20px;
    .icon_div{
        margin-right: 20px;
    }
}
.single_row{
    margin-bottom: 40px;
}

.search_page {
    p {
      font-size: 12px;
      text-transform: uppercase;
      color: var(--color-gray);
      padding-bottom: 10px;
      font-family: "Roboto", sans-serif;
    }
  }