.personal_details {
  .card {
    background-color: var(--color-card-light-green);
    border-radius: 20px;
    border: 1px solid var(--color-border);
    padding: 30px;
  }
}
.person_name {
  color: var(--color-black);
  font-size: 26px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 10px;
}
.personal_details_row {
  img {
    margin-right: 10px;
  }
  .details_div {
    align-items: center;
  }
}
.left_div {
  .card {
    background-color: var(--color-white);
    border-radius: 20px;
    border: 1px solid var(--color-border);
    padding: 30px;
    margin-bottom: 20px;
    .primary_btn {
      margin-top: 30px;
      width: auto;
      display: flex;
      margin-right: auto;
    }
  }
  .rera_card {
    background-color: var(--color-card-light)!important;
  }
}
.ratings {
  .card {
    border: 1px solid var(--color-border);
    border-radius: 20px;
    padding: 30px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 20px;
      margin-bottom: 20px;
    }
    .body_para {
      margin: 20px 0px 30px;
    }
  }
}
.package_sec {
    .left_div{
        .card{
            margin-top: 20px;
        }
    }
  .card {
    background-color: var(--color-white);
    border-radius: 20px;
    border: 1px solid var(--color-border);
    padding: 20px;
  }
}
.plan_days {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}
.price {
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.plan_ben {
  padding: 30px 0px 20px;
  img {
    margin-right: 10px;
  }
  .d-flex {
    margin-bottom: 5px;
  }
}
.package_sec {
  .card:hover {
    background-color: var(--color-card-light-green);
    cursor: pointer;
  }
}
.buy_btn {
  height: 70px;
}
.history{
    .row{
        margin-bottom: 20px;
    }
    .agent_dtl{
        border: 1px solid var(--color-border);
        padding: 20px 40px;
        border-radius: 20px;
    }
}
.toggle_label{
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}
.custom-control-input:checked + .custom-control-label::before {
  background-color: #00B63E;
  border: none;
}
.notifications_settings{
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 20px;
  padding: 20px 20px;
  margin-bottom: 20px;
}
.notification_text{
  padding: 0px 20px;
  h5{
    font-family: 'Unna';
    font-weight: 700;
    font-size: 26px;
    color: #222222;
  }
  p{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    color: #222222;
  }
}