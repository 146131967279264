.ad_banner_item {
  padding: 150px 100px 100px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  h2{
      color: var(--color-white);
      font-size: var(--font-size-ad-banner-head);
      line-height: 80px;
      font-weight: var(--font-weight-bold);
      letter-spacing: -1px;
  }
  .icon{
      width: 30px!important;
  }
  .btns_div{
      width: 100%;
      button{
        margin-right: 20px;
      }
  }
  p{
    color: var(--color-white);
    font-size: 19px; 
    font-weight: var(--font-weight-normal);
    line-height: 26px;
    margin: 12px 0px 30px;
    font-family: 'Roboto', sans-serif;
  }
}
.ads_banner{
    position: relative;
}
.ads_car{
    .owl-dots{
        bottom: 80px;
        right: 80px;
    }
}
