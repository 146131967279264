.top_builders{
 .card{
    border: none;
    border-radius: 20px;
    .main_img{
        width: 100%;
    }
    .card_body{
        padding: 20px;
        border:1px solid var(--color-border);
        border-radius: 0px 0px 20px 20px;
    }
 }
}
.price_sec{
    margin-top: 20px;
}
.images_sec{
    position: relative;
}
.logo_image{
    img{
      position: absolute;
      bottom: 10px;
      left: 10px;
      border-radius: 10px;
      z-index: 9;
    }
}
.property_car{
    .owl-dots{
        display: block!important;
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    .owl-nav{
        display: none;
    }
     .owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span {
        background:var(--color-white)!important;
    }
     .owl-dots .owl-dot span{
         background: var(--color-gray)!important;
     }
}