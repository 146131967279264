.image-tabs{
    background: #FFFFFF;
    font-family: 'Roboto';
    color: #717171;
    font-weight: 600;
    font-size: 12px;
    border: none;
    margin-bottom: 10px;
    a{
        color: #717171;
    }
}
.img-link{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 13px;
    color: #717171;
    margin-bottom: 10px;
}
.img-link.active{
    color: #002F10;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 13px;
    padding: 10px 20px 10px 20px;
    background: linear-gradient(270deg, #D49941 5.14%, rgba(255, 153, 0, 0) 85.41%);
    border-radius: 40px;
}
.img-tab{
    margin-right: 10px;
}
.parent_div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.image_upload_div{
    padding: 50px 50px;
}
.image_txt{
    p{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 16px;
        color: #002F10;
        margin-bottom: 30px;
    }
    button{
        background: #002F10;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        color: #D49941;
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 15px;
        cursor: pointer;
        padding: 10px 20px;
        border:none;
    }
    input{
        cursor: pointer;
    }
}
.parent_div{
    background: #FFF2DF;
    padding: 20px 20px;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
}