.banner {
  background-color: var(--color-primary);
  border-radius: 20px;
  padding: 70px 180px;
  h1 {
    font-size: var(--font-size-main-head);
    font-weight: var(--font-weight-bold);
    line-height: 80px;
    color: var(--color-white);
    margin-bottom: 60px;
    text-align: center;
  }
}
.filters_sec {
  border: 1px solid var(--color-secondary);
  border-radius: 30px;
  position: relative;
  padding: 25px 0px 30px;
  .want_buy_text {
    font-size: 19px;
    color: var(--color-white);
    background-color: var(--color-primary);
    padding: 5px;
    top: -20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.home_main_tabs,
.home_child_tabs {
  .nav-link {
    font-family: "Roboto", sans-serif;
    background-color: transparent;
    color: var(--color-white);
    font-size: var(--font-size-tab-text);
    font-weight: var(--font-weight-medium);
  }
}
.home_main_tabs {
  justify-content: center;
  border-bottom: 1px solid var(--color-secondary);

  .nav-item.show .nav-link,
  .nav-link.active {
    color: var(--color-secondary);
    background-color: transparent;
    border: none;
    border-bottom: 3px solid var(--color-secondary);
  }
  .nav-item {
    margin: 0px 40px;
  }
}
.main_banner {
  padding-top: 30px;
}
.tab_padding {
  padding: 30px;
}
.home_child_tabs {
  border-bottom: none;
  justify-content: center;
  .nav-item.show .nav-link,
  .nav-link.active {
    color: var(--color-white);
    background-color: transparent;
    border: none;
    background: linear-gradient(
      270deg,
      #d49941 -15.14%,
      rgba(255, 153, 0, 0) 85.41%
    );
    border-radius: 40px;
  }
}
.filters_sec {
  .input-group-prepend {
    position: absolute;
    z-index: 9;
    left: 10px;
  }
  .form-control {
    padding: 30px;
    border: none;
    border-radius: 40px !important;
    padding-left: 50px;
  }
}
.allproperty_card {
  background-color: var(--color-card-light);
  padding: 60px 100px;
  border-radius: 20px;
  ul {
    li {
      font-family: "Roboto", sans-serif;
      font-size: var(--font-size-body-text);
      color: var(--color-black);
      line-height: 19px;
      margin-bottom: 10px;
    }
  }
  .allproperty_card_body {
    height: 300px;
    overflow-y: auto;
  }
  .card_head {
    margin-bottom: 20px;
  }
}
.disclaimer_sec{
  background-color: var(--color-border);
  border-radius: 20px;
  padding: 60px 100px;
  .card_head{
    margin-bottom: 20px;
  }
  p{
    margin-bottom: 22px;
  }
}
.filter-popup-modal{
  max-width: 1100px;
}
.filter-modal-scroll{
  overflow-y: auto;
  height: calc(100vh - 240px);
}
.listbuy_button{
  button{
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 5px 10px;
  }
 }
 .unselected_btn{
  font-weight: 600;
  font-size: 16px;
  font-family: 'Roboto';
  font-style: normal;
  background: #FFFFFF;
  color: #222222;
 }
 .selected_value{
  background: #002F10;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
 }
 .listbuy_button{
  margin-bottom: 20px;
 }
.properties{
  button{
    border: 1px solid #DDDDDD;
    padding: 10px 20px;
    border-radius: 10px;
  }
  margin-bottom: 20px;
}
.unselected_filter{
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 12px;
  color: #222222;
  background: #FFFFFF;
}
.selected_filter{
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 12px;
  background: #002F10;
  border-radius: 10px;
  color: #FFFFFF;
}
.roomsAndBalcony,.floorno,.total_floors,.property_area,.trans_type,.car_parkings{
  margin-bottom: 20px;
}
.modal-footer{
  display: block;
  border-top: none;
} 

.danger_color{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
    color: #EE2F2F;
    background: #FFF2DF;
    border-radius: 60px;
    border: none;
    margin-bottom: 0px;
  }
  .apply_btn{
    background: #002F10;
    border-radius: 40px;
    font-family: 'Roboto';
    border: none;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
  }